<template>
    <div class="epg-programme-container">
        <div class="progress-container">
            <div class="current-live-title" v-if="channelId">
                <span>{{channelId}}</span>
            </div>

            <div class="current-live-title">
                <span>{{activeProgramPro && activeProgramPro.title ? activeProgramPro.title : `${$t('Program Guide')}...` }}</span>
            </div>
            <!-- <div class="progress-bar"> -->
                <progress :value="progressValue" style="width: 100%;color: red;"></progress>
                <!-- <input type="range" value="0" min="0" max="100" step="1" id="slider"> -->
            <!-- </div> -->
            <div class="time-lenth">
                <span class="updated-length">{{activeProgramPro && activeProgramPro.startTime ? activeProgramPro.startTime : '00:00'}}</span>
                <span class="total-length">{{activeProgramPro && activeProgramPro.endTime ? activeProgramPro.endTime : '00:00'}}</span>
            </div>
        </div>
        <div class="program-data-table mt-20 box-shadow">
            <!-- <div class="calender-date wrapper d-flex scroll-hide">
                <div class="date-item hand" :class="[selectedDateIndex == i ? 'active' : '']" v-for="(item, i) in weekArray" :key="i" @click="actSelectedDate(item, i)">
                    <p>{{ item.dayName}}</p><br />
                    <p>{{ item.date }}</p>
                </div>
            </div> -->
            <div class="program-wrapper scroll-hide" v-if="activeProgram && activeProgram.length > 0">
                <div v-for="(program, i) in activeProgram"  :key="i" class="single-row d-flex hand" :class="[isCurrentProgram(program) ? 'active' : '']" @click="actProgramPlay(program)" :id="isCurrentProgram(program) ? 'currentProgram' : 'program'">
                    <div class="flex-item_time">{{ getFormatStartEndTime(program)}}</div>
                    <div class="flex-item_title">{{ program.title }}</div>
                    <div class="flex-item_catchup" id="live-item-catchup" v-if="isCurrentProgram(program)">▷ LIVE</div>
                </div>
            </div>                        
            <div v-else-if="activeProgram && activeProgram.length <= 0" class="program-wrapper load-wrapper">
                NO PROGRAMS AVAILABLE
            </div>
            <div v-else class="program-wrapper load-wrapper">
                <!-- Loading... -->
                <WaveLoader :loaderHeight="loaderHeight" />
            </div>
        </div>
        <Popup  v-if="isLiveContentAccessError" islogoNotRequired="false" width="max-content" margin="15% auto" radiusType="full" :closePopupEvent="() => { closePopup() }">
            <div class="confirmation-box" slot="component" >
            <div  class="confirmation-body">
                <h5>{{activeChannel['display-name']}}{{$t("liveContentAccessError")}}</h5>
                <div class="login-button">
                <button class="button-primary login" type="Button" @click.stop="closePopup()">
                    {{ $t("OK") }}
                </button>
                </div>
            </div>
            </div>
        </Popup>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { eventBus } from "@/eventBus";
import moment from "moment";
import PlaybackUtilities from "@/mixins/playbackUtilities.js";
import Redirection from '@/mixins/redirection.js';

export default {
    name: 'ProgramContainer',
    props: ['selectedChannelIndex', 'activeChannelData', 'finalData', 'activeProgramData'],
    data() {
        return {
            selectedDateIndex: 1,
            loaderHeight: "2.5rem",
            weekArray: [],
            activeProgramPro: null,
            progressValue: 0,
            localDisplayLang: null,
            channelId: null,
            sortedData: [],
            programsData: null,
            channelsData: null,
            activeProgram: null,
            activeChannel: null,
            isLiveContentAccessError: false
        }
    },    
    computed: {
        ...mapGetters(["subscriberid", "appConfig", "profileid", "purchaseList", "availabilityList", "subscriptionList"]),
    },

    watch: {
        selectedChannelIndex (newVal, oldVal) {
            this.activeProgram = null;
            // this.activeProgramProgress();
        },
        activeProgramData (newVal, oldVal) {
            this.activeProgram = null;
            this.activeProgramPro = null;
            this.progressValue = 0;
            setTimeout(()=>{
                this.activeProgram = newVal;
                this.activeProgramProgress();
            }, 100);
        },
        activeChannelData (newVal, oldVal) {
            this.activeChannel = newVal;
        }
    },
    created() {
    },
    mounted() {
        console.log('ll', this.activeChannelData)
        // this.getWeek();
        this.activeProgram = this.activeProgramData;
        this.activeChannel = this.activeChannelData;
        this.activeProgramProgress();
    },
    mixins: [PlaybackUtilities, Redirection],
    methods: {
        ...mapActions(['mapEpgXmlData', 'getContent']),
        closePopup() {
            this.isLiveContentAccessError = false;
        },
        actSelectedDate(date, i) {
            this.selectedDateIndex = i;
            this.progressValue = 0;
            this.activeProgramPro = null;
            this.$emit("actSelectDate", date.date);
        },
        isCurrentProgram(item) {
            let currentTime = moment.utc().local().valueOf();
            let startTime = moment.utc(moment(item.start, "YYYYMMDDHHmmss Z")).local().valueOf();
            let endTime  = moment.utc(moment(item.stop, "YYYYMMDDHHmmss Z")).local().valueOf();         
            return (startTime <= currentTime) &&  (currentTime <= endTime);
        },
        isFutureProgram(item) {
            let currentTime = moment().utc().local().valueOf();
            let startTime = moment.utc(moment(item.start, "YYYYMMDDHHmmss Z")).local().valueOf();
            return startTime > currentTime;
        },
        actProgramPlay(program) {
            let self = this;
            this.$emit('actSelectedProgram', true);
            if (this.subscriberid) {
                this.getContent(this.activeChannel.contentid).then(async (item) => {
                    let episodeIndex = 0;
                    let episodeList = [item.data];
                    let totalEpisodeCount = 1;
                    let contentData = item.data;
                    let isPlaybackAllowed = await this.checkAvailabilityAllowsPlayback(contentData);
                    if(!isPlaybackAllowed) {
                        this.$emit('actSelectedProgram', false);
                        this.isLiveContentAccessError = true;
                        // this.redirectToPlans();
                        return;
                    }
                    if(!self.isCurrentProgram(program) && !self.isFutureProgram(program)) {
                        contentData.program = program;
                    }
                    this.$emit('actSelectedProgram', false);
                    eventBus.$emit("live-player-active", item.data);
                    // this.loadChosenIndexContentLiveStream(episodeIndex, episodeList, totalEpisodeCount, true, program);
                
                }).catch((err) => console.log("err", err));
            } else {
                let payload = {
                state: true,
                formType: "lookup",
                };
                eventBus.$emit("authPopup", payload);
                this.$emit('actSelectedProgram', false);
            }
        },
        getFormatStartEndTime(item) {
            let startTime = moment.utc(moment(item.start, "YYYYMMDDHHmmss Z")).local().format("YYYY-MM-DD HH:mm:ss");
            startTime = moment(startTime).format("HH:mm");
            let endTime  = moment.utc(moment(item.stop, "YYYYMMDDHHmmss Z")).local().format("YYYY-MM-DD HH:mm:ss");
            endTime = moment(endTime).format("HH:mm");
            return startTime + ' - ' + endTime;
        },
        getWeek() {
            // Get the current date
            let currentDate = new Date();

            // Create an empty array to store the dates
            // var dateArray = [];
            this.weekArray = [];
            // Generate three days before the current date
            for (var i = 1; i > 0; i--) {
                var previousDate = new Date(currentDate);
                previousDate.setDate(currentDate.getDate() - i);
                this.weekArray.push({ date : previousDate.toISOString().split('T')[0], dayName : previousDate.toString().split(" ")[0] });
            }

            // Add the current date
            this.weekArray.push({ date : currentDate.toISOString().split('T')[0], dayName : currentDate.toString().split(' ')[0] });

            // Generate three days after the current date
            for (var i = 1; i <= 6; i++) {
                var nextDate = new Date(currentDate);
                nextDate.setDate(currentDate.getDate() + i);
                this.weekArray.push({ date : nextDate.toISOString().split('T')[0], dayName : nextDate.toString().split(' ')[0]});
            }
        },
        activeProgramProgress(val) {
            let self = this;
            if(this.activeProgram?.length > 0) {
                this.activeProgramPro = {};
                this.activeProgram?.filter((item, i) => {
                    if(self.isCurrentProgram(item)){            
                        let startT = moment.utc(item.start, "YYYYMMDDHHmmss Z").local().format("YYYY-MM-DD HH:mm:ss");
                        startT = moment(startT).format("HH:mm");            
                        let endT  = moment.utc(item.stop, "YYYYMMDDHHmmss Z").local().format("YYYY-MM-DD HH:mm:ss");
                        endT = moment(endT).format("HH:mm");
                        
                        this.activeProgramPro.title = item.title;
                        this.activeProgramPro.startTime = startT;
                        this.activeProgramPro.endTime = endT;
                        this.activeProgramPro.channel = item.channel;
                        this.currentDuration(startT, endT);
                        return;
                    }
                });
            }
        },
        currentDuration(startTime, endTime) {
            let newDate = moment.utc(moment()).local().format("YYYY-MM-DD HH:mm:ss");
            newDate = moment(newDate).format("HH:mm");
            let totalTimeGap = moment.utc(moment(endTime, "HH:mm").local().diff(moment(startTime, "HH:mm"))).format("HH:mm");
            let currentTimeGap = moment.utc(moment(newDate, "HH:mm").local().diff(moment(startTime, "HH:mm"))).format("HH:mm");
   
            if(totalTimeGap.split(':')[0] == 0) {
                totalTimeGap = parseInt(totalTimeGap.split(':')[1]);
            } else if(totalTimeGap.split(':')[0] > 0) {
                totalTimeGap = parseInt(totalTimeGap.split(':')[0])*60 + parseInt(totalTimeGap.split(':')[1]);
            }
            if (currentTimeGap.split(':')[0] == 0) {
                currentTimeGap = parseInt(currentTimeGap.split(':')[1]);
            } else if(currentTimeGap.split(':')[0] > 0) {
                currentTimeGap = parseInt(currentTimeGap.split(':')[0])*60 + parseInt(currentTimeGap.split(':')[1]);
            }
            this.progressValue = !currentTimeGap ? currentTimeGap : ((currentTimeGap) / (totalTimeGap));
        },
        async sortedProgramsData() {
            let filterData = [];
            return new Promise((resolve, reject) => {
                try {
                    let singleData = this.programsData?.filter((item, i) => {
                        return item?.channel == this.channelsData[this.selectedChannelIndex]?.id;
                    });
                    if(singleData) {
                        filterData = singleData?.sort((a, b) => (moment.utc(moment(a.start, "YYYYMMDDHHmmss Z")).local().valueOf()) - (moment.utc(moment(b.start, "YYYYMMDDHHmmss Z")).local().valueOf()));
                    }
                    resolve(filterData);
                } catch (error) {
                    resolve(filterData);
                }
            })
        }
    },
    components: {
        WaveLoader: () =>
            import(/* webpackChunkName: "waveLoader" */ "@/components/Loaders/WaveLoader.vue"),
            Popup: () => import(/* webpackChunkName: "Popup" */ "@/components/Slots/Popup.vue"),
    }
}
</script>
<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
// @import "./Epg.scss";

.d-flex {
    display: flex;
}
.mt-20 {
    margin-top: 1.5rem;
}
.load-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.box-shadow {    
    box-shadow: 1px 0px 5px 1px #ddd;
}
.active { 
    background: $primary-btn-color;
    color: #FFFFFF !important;
}
.hand {
    cursor: pointer;
};
p, .flex-item_time, .flex-item_title, .flex-item_catchup {
  pointer-events: none;
}
.date-item, .single-row:hover:not(.active) {
  background-color: black;
}
progress {
    height: 0.6em;
    border: 1.5px solid $clr-text;
    border-radius: 0.5rem;
    background-color: $clr-create-main-txt;
 }
 progress::-webkit-progress-bar {
    background-color: $clr-create-main-txt;
    border-radius: 0.5rem;
 }
 progress::-webkit-progress-value {
    //background-color: $primary-btn-color;
    background-color: red;
    border-radius: 0.5rem;
 }
.scroll-hide {    
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}::-webkit-scrollbar {
    display: none;
}
.epg-programme-container {
    height: 100%;
    color: $clr-create-main-txt !important;
    .progress-container {
        width: 100%;
        input[type="range"] {
            -webkit-appearance: none;
            width: 100%;
            height: 5px;
            background-color: #ddd;
            outline: none;
            opacity: 0.7;
            transition: opacity 0.2s;
        }
        
        input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $primary-btn-color;
        cursor: pointer;
        }
        
        input[type="range"]::-moz-range-thumb {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $primary-btn-color;
        cursor: pointer;
        }
        .time-lenth {
            display: flex;
            justify-content: space-between;
            font-size: 0.8rem;
        }
        
    }
    .program-data-table {
        width: 100%;
        height: calc(100% - 4.8rem);
        padding-bottom: 10px;
        .calender-date{
            font-size: 0.8rem;
            text-align: center;
            border-bottom: 1px solid #ccc; 
            line-height: 1em;
            overflow: auto;
            white-space: nowrap;
            .date-item:not(:last-child){
                border-right: 2px solid #ccc;;
            }
            .date-item {
                padding: 1rem;
            }:hover {
                background-color: #707070;
                color: $clr-create-main-txt !important;
            }

        }
        .program-wrapper {
            height: calc(100%);
            width: 100%;
            overflow-y: auto;
            .single-row {
                padding: 10px 10px;
                border-bottom: 1px solid #ccc; 
                align-items: center;
                .flex-item_time {
                    flex-basis: 25%;
                    padding-left: 0.313rem;
                }
                .flex-item_title {
                    flex-basis: 50%;
                }
                .flex-item_catchup {
                    text-align: end;
                    flex-basis: 25%;
                    padding-right: 0.313rem;
                }
            }:hover {
                background-color: #707070;
            }
            .single-row:not(:last-child) {                            
                border-bottom: 1px solid #ccc; 
            }
    }
    }
}
@media screen and (min-width: 1281px) {
    .program-data-table {
        width: 100%;
        .calender-date{
            font-size: 1rem;
            .date-item:not(:last-child){
                border-right: 2px solid #ccc;;
            }
            .date-item {
                padding: 1.5rem 0rem;
                width: calc(100% / 8);
            }

        }
        .program-wrapper {
            .single-row {
                font-size: 1rem;
            }
        }
    }
}
@media only screen and (min-width: 540px) and (max-width: 768px) {
    .epg-programme-container {
        // width: 88%;
        height: calc(100% - 10px);
        .program-data-table {
            width: 100%;
            height: calc(100% - 90px);
            .calender-date{
                font-size: 0.8rem;
                text-align: center;
                border-bottom: 1px solid #ccc; 
                line-height: 1em;
                .date-item:not(:last-child){
                    border-right: 2px solid #ccc;;
                }
                .date-item {
                    padding: 1rem;
                }

            }
            .program-wrapper {
                width: 100%;
                overflow-y: auto;
                .single-row {
                    padding: 10px 10px;
                    font-size: 0.8rem;
                    align-items: center;
                    .flex-item_time {
                        flex-basis: 25%;
                        padding-left: 0.313rem;
                    }
                    .flex-item_title {
                        flex-basis: 60%;
                    }
                    .flex-item_catchup {
                        text-align: end;
                        flex-basis: 20%;
                        padding-right: 0.313rem;
                    }
                }:hover {
                    background-color: #707070;
                }
        }
        }
    }
}
@media only screen and (max-width: 539px) {
    .epg-programme-container {
        // width: 78%;
        height: calc(100% - 10px);
        .progress-container {
            .current-live-title {
                font-size: 0.8rem;
            }
        }
        .program-data-table {
            width: 100%;
            height: calc(100% - 90px);
            .calender-date{
                font-size: 0.8rem;
                text-align: center;
                border-bottom: 1px solid #ccc; 
                line-height: 1em;
                .date-item:not(:last-child){
                    border-right: 2px solid #ccc;;
                }
                .date-item {
                    padding: 1rem;
                }

            }
            .program-wrapper {
                width: 100%;
                overflow-y: auto;
                .single-row {
                    padding: 10px 10px;
                    font-size: 0.6rem;
                    align-items: center;
                    .flex-item_time {
                        flex-basis: 25%;
                        padding-left: 0.313rem;
                    }
                    .flex-item_title {
                        flex-basis: 60%;
                    }
                    .flex-item_catchup {
                        text-align: end;
                        flex-basis: 20%;
                        padding-right: 0.313rem;
                    }
                }:hover {
                    background-color: #707070;
                }
        }
    }
}
}
.confirmation-box {
  .confirmation-body {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
    text-align: center;
    .login-button {
      width: 50%;
    }
  }
}
</style>